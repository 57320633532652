import React from "react"
import { Link, graphql } from "gatsby"
import widont from "widont"

import Layout from "../../components/layout"
import OffsetGrid from "../../components/athena/offsetGrid"
import Image from "../../components/image"
import PreFooter from "../../components/athena/preFooter"
import Arrow from "../../images/arrow-black.svg"
import styles from "./issues.module.scss"

export const query = graphql`
  query {
    craft {
      athenaHome: entry(section: "athena") {
        ... on Craft_athena_athena_Entry {
          featuredIssue {
            id
          }
        }
      }
      page: entry(section: "athenaIssuesIndex") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
      }
      issues: categories(group: "athenaIssues") {
        id
        title
        uri
        ... on Craft_athenaIssues_Category {
          id
          issueSubtitle
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_1x1 {
                url
              }
              ratio_4x3 {
                url
              }
              ratio_3x4 {
                url
              }
              ratio_16x9 {
                url
              }
            }
          }
        }
      }
    }
  }
`

const AthenaPage = ({ data }) => {
  const page = data.craft.page
  const seo = page.seomatic
  const issues = data.craft.issues
  const aspectRatios = ["1x1", "3x4", "4x3"]
  const featuredIssueId = data.craft.athenaHome.featuredIssue[0].id
  let arIndex = 0

  // Add all articles to an array that we'll pass to <OffsetGrid />
  const gridItems =
    issues.length > 0
      ? // Loop through issues in reverse order
        issues
          .slice(0)
          .reverse()
          .map((issue, i) => {
            // Link to the homepage if it's the latest issue
            const isFeatured = issue.id === featuredIssueId
            // Loop through set aspect ratios
            const numOfAspectRatios = aspectRatios.length - 1
            let ar = aspectRatios[arIndex]
            arIndex = arIndex === numOfAspectRatios ? 0 : ++arIndex
            return (
              <div key={`issue-index-${issue.id}`}>
                <Link
                  className="img-tile"
                  to={`/${isFeatured ? "athena" : issue.uri}`}
                >
                  <div className="mb-4">
                    {/*
                      This allows us to use the padding-bottom aspect ratio
                      technique so content doesn't jump or get measured incorrectly
                    */}
                    <div
                      className={`img-tile-image img-tile-ar img-tile-ar-${ar}`}
                    >
                      <Image
                        height={issue.coverImages[0][`ratio_${ar}`][0].height}
                        width={issue.coverImages[0][`ratio_${ar}`][0].width}
                        sizes="(min-width: 1216px) 536px, (min-width: 1024px) calc(42vw - 2.5rem), (min-width: 768px) calc(50vw - 2.5rem), calc(100vw - 2.5rem)"
                        url={issue.coverImages[0][`ratio_${ar}`][0].url}
                        altText={issue.coverImages[0][`ratio_${ar}`][0].altText}
                      />
                    </div>
                  </div>
                  <div className={styles.issueText}>
                    <div aria-hidden={true} className="mono body-medium">
                      Issue {(issues.length - i).toString().padStart(2, "0")}
                    </div>
                    <h3 className="a-h-2 mb-2 italic">
                      <span className="img-tile-title-underline">
                        {issue.title}
                      </span>
                    </h3>
                    <p
                      className="body"
                      dangerouslySetInnerHTML={{
                        __html: widont(issue.issueSubtitle),
                      }}
                    />
                    <div className={styles.issueArrow}>
                      <Arrow />
                    </div>
                  </div>
                </Link>
              </div>
            )
          })
      : null

  return (
    <Layout seo={seo} mode="athena">
      <div className="page-content-module">
        <div className="wrapper">
          <div className={styles.title}>
            <h1 className="a-h-1">{page.title}</h1>
          </div>
          <div className={styles.issues}>
            <OffsetGrid items={gridItems} />
          </div>
        </div>
      </div>
      <PreFooter />
    </Layout>
  )
}

export default AthenaPage
